<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">设备同步</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model ref="ruleForm" :model="form" labelAlign="left">
			<a-form-model-item layout="inline">
				<a style="color:crimson" disabled>增量同步:同步绿洲新增设备以及设备状态 </a>
			</a-form-model-item>
			<a-form-model-item>
				<a-button type="primary" html-type="submit" @click="sync">
					增量同步
				</a-button>
			</a-form-model-item>
			<a-form-model-item layout="inline">
				<a style="color:crimson" disabled>删除同步:同步绿洲比较我们系统删除的设备</a>
			</a-form-model-item>
			<a-form-model-item>
				<a-button type="danger" html-type="danger" @click="syncDelete">
					删除同步
				</a-button>
			</a-form-model-item>
		</a-form-model>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'
import {
	get
} from 'js-cookie'
import {
	type
} from 'os'

export default {
	data() {
		return {
			form: {},
		}
	},
	methods: {
		sync() {
			// this.$message.warn('数据维护中，该功能暂停服务', 1.5)
			this.$post(domain + '/admin/v1/test/deviceTask', {

			})
				.then(res => {
					let {
						code,
						msg,
						data
					} = res
					if (code == 0) {
						this.$message.success(msg, 1.5)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.$message.success("正在处理中,稍后查看日志确定信息", 1.5);
				})
		},
		syncDelete() {
			// this.$message.warn('数据维护中，该功能暂停服务', 1.5)
			this.$post(domain + '/admin/v1/test/lvZhouDeleteSync', {

			})
				.then(res => {
					let {
						code,
						msg,
						data
					} = res
					if (code == 0) {
						this.$message.success(msg, 1.5)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.$message.success("正在处理中,稍后查看日志确定信息", 1.5);
				})
		}
	},
}
</script>

<style lang="less" scoped>
.img-box {
	overflow: hidden;
	display: inline-block;

	.img-icon {
		transform: translateX(-100px);
		filter: drop-shadow(blue 100px 0)
	}
}

.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
